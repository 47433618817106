import React, { useEffect, useContext } from "react";
import { BrowserRouter } from "react-router-dom";
import Routes from "./Routes";
import { AptapMainContext } from "./Context";
import { ApolloProvider } from "@apollo/client";
import client from "./views/AptapLanding/utils/apolloClient";
import updateWindowZoom from "./helpers/updateWindowZoom";

function App(props) {
  const aptapMainContext = useContext(AptapMainContext);

  useEffect(() => {
    window.addEventListener("resize", updateWindowZoom);
    updateWindowZoom();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    updateWindowZoom();
  }, [window.location.pathname]);

  if (
    window.location.hostname === 'creation.aptap.co.uk' &&
    !window.location.pathname.includes('/mpredirect')
  ) window.location.href = 'https://www.aptap.co.uk';

  const setPathname = (pathname) => {
    localStorage.setItem("newpathname", pathname);
  };

  if (aptapMainContext.browserType.isIE) {
    props.history.push("/unsupported.html");
    props.history.go(0);
  }

  //localStorage.appType = "apTap";
  // if(localStorage.appType != "WeAreDiscounts")
  // {
  //   localStorage.appType = "apTap";
  // }

  // storing appType

  //TODO: userAuthenticated check ini de eklemmiz gerekiyor
  // localStorage.appType = "apTap";

  var user = JSON.parse(localStorage.getItem("userAuthenticated"));

  var fromAppType = localStorage.getItem("fromAppType");

  if (
    window.location.href.indexOf("/managemybills") > -1 ||
    (user && user.profile && user.profile.nickname === "APTEXT_67238c17") ||
    fromAppType === "Network"
  ) {
    localStorage.appType = "Network";
  } else {
    localStorage.appType = "apTap";
  }
  localStorage.removeItem("fromAppType");

  var logoutAppType = localStorage.getItem("logoutAppType");
  if (logoutAppType === "Network") {
    window.location.href = "/managemybills/login-page";
  }

  localStorage.removeItem("logoutAppType");
  return (
    <ApolloProvider client={client}>
      <BrowserRouter history={props.history}>
        <Routes setPathname={setPathname} />
      </BrowserRouter>
    </ApolloProvider>
  );
}

export default App;
