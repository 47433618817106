// These must be the first lines in src/index.js
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";

// import 'bootstrap/dist/css/bootstrap.min.css';
import ReactDOM from "react-dom/client";
// import 'core-js';

import "./index.css";
import "./views/Landing/assets/css/bootstrap.min.css";
import "./views/Landing/assets/css/animate.css";
import "./views/Landing/assets/css/custom-animation.css";
import "./views/Landing/assets/css/slick.css";
import "./views/Landing/assets/css/nice-select.css";
import "./views/Landing/assets/css/flaticon.css";
import "./views/Landing/assets/css/swiper-bundle.css";
import "./views/Landing/assets/css/meanmenu.css";
import "./views/Landing/assets/css/font-awesome-pro.css";
import "./views/Landing/assets/css/magnific-popup.css";
import "./views/Landing/assets/css/spacing.css";
// import "./views/Landing/assets/css/style.css";

import App from "./App";
import * as serviceWorker from "./serviceWorker";

import oldStore from "./js/store/index";
import { Provider as OldProvider } from "react-redux";

// import { store } from './redux/store';
// import { Provider } from 'react-redux';

import { BASE_URL } from "./network/networkConstants";
import mixpanelProjectTokens from "./helpers/mixpanelProjectTokens";
import ErrorBoundary from "./ErrorBoundary";
import { AptapMainProvider } from "./Context";
import { createBrowserHistory } from "history";

import { ThemeProvider, StyledEngineProvider } from "@mui/material";
import { createTheme } from "@mui/material/styles";

const history = createBrowserHistory();
const isDevEnvironment =
  !process.env.NODE_ENV || process.env.NODE_ENV === "development";

const theme = createTheme();

var mixpanel = require("mixpanel-browser");

// First line covers cases like /marketplace/tsb/broadband.
// Second line covers cases like /marketplace/tsb.
// Third case covers cases like tsb.aptap.co.uk.
const currentMixpanelProject = mixpanelProjectTokens.find(p =>
  window.location.pathname.startsWith(`/marketplace/${p.name}/`) ||
  window.location.pathname.endsWith(`/marketplace/${p.name}`) ||
  window.location.hostname === `${p.name}.aptap.co.uk`
);

if (
  !isDevEnvironment &&
  !window.location.hostname.includes('sandbox') &&
  currentMixpanelProject
) {
  mixpanel.init(currentMixpanelProject.token, {
    api_host: BASE_URL + "mixpanel",
    ignore_dnt: true,
    persistence: 'localStorage',
  });
} else {
  mixpanel.init("a97fbdc5836389407e49cd72eb9da573", {
    api_host: BASE_URL + "mixpanel",
    ignore_dnt: true,
    persistence: 'localStorage',
  });

  mixpanel.init(
    isDevEnvironment || window.location.hostname.includes('sandbox')
      ? 'a97fbdc5836389407e49cd72eb9da573'
      : '06f6ae8ffdcc2145fa26f04e23ad5ca2',
    { api_host: BASE_URL + "mixpanel", ignore_dnt: true, persistence: 'localStorage' }
  , 'amplify');

  mixpanel.init(
    isDevEnvironment || window.location.hostname.includes('sandbox')
      ? 'a97fbdc5836389407e49cd72eb9da573'
      : 'b9ed6561b2bd0ff8e396408711645066',
    { api_host: BASE_URL + "mixpanel", ignore_dnt: true, persistence: 'localStorage' }
  , 'landing');
}

ReactDOM.createRoot(document.getElementById("root")).render(
  <StyledEngineProvider injectFirst>
    <ThemeProvider theme={theme}>
      <ErrorBoundary>
        <OldProvider store={oldStore}>
          {/* <Provider store={store}> */}
          <AptapMainProvider>
            <App history={history} />
          </AptapMainProvider>
          {/* </Provider> */}
        </OldProvider>
      </ErrorBoundary>
    </ThemeProvider>
  </StyledEngineProvider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
