export class OicdConstants {
    // public static stsAuthority = 'https://api2.codeanddreams.com:5001/';
    // public static clientId = 'aptapspa';
    // public static clientRoot = 'http://localhost:3000/';
    // public static clientScope = 'openid profile email apiGateway identityAPI energyAPI broadbandAPI algorithmAPI offline_access';
    // public static responseType = 'code';

    // public static apiRoot = 'https://api2.codeanddreams.com:5001/';


    // public static stsAuthority = 'https://apigateway.aptapapp.co.uk/';
    // public static clientId = 'aptapspa';
    // public static clientRoot = 'https://frontend.aptapapp.co.uk/';
    // public static clientScope = 'openid profile email apiGateway identityAPI energyAPI broadbandAPI algorithmAPI offline_access';
    // public static responseType = 'code';

    // public static apiRoot = 'https://apigateway.aptapapp.co.uk/';

    public static stsAuthority = 'https://apigateway.sandbox.aptapapp.co.uk/';
    public static clientId = 'aptapspa';
    public static clientRoot = 'https://www.frontend.sandbox.aptapapp.co.uk/';
    public static clientScope = 'openid profile email apiGateway identityAPI energyAPI broadbandAPI algorithmAPI offline_access';
    public static responseType = 'code';
  
    public static apiRoot = 'https://apigateway.sandbox.aptapapp.co.uk/';

    
    // public static stsAuthority = 'http://identityserver.dev.aptapapi.internal/';
    // public static clientId = 'aptapspa';
    // public static clientRoot = 'http://localhost:3000/';
    // public static clientScope = 'openid profile email apiGateway identityAPI energyAPI broadbandAPI algorithmAPI offline_access';
    // public static responseType = 'code';

    // public static apiRoot = 'http://identityserver.dev.aptapapi.internal/';

    // public static stsAuthority = 'https://localhost:5001/';
    // public static clientId = 'aptapspa';
    // public static clientRoot = 'http://localhost:3000/';
    // public static clientScope = 'openid profile email apiGateway identityAPI energyAPI broadbandAPI algorithmAPI offline_access';
    // public static responseType = 'code';
    // public static apiRoot = 'https://localhost:5001/';

}
